.step-wrapper {
    max-width: 100%;

    .step-container {
        margin: 10px 0;
        // box-shadow: 0 0 8px 1px $gray-300;
        border-radius: 5px;
    }

    img {
        height: auto;
        width: 100%;
        border-radius: 5px;
    }
}

.product-hunt-faq {
    p {
        color: $black;
    }
    span {
        font-weight: 700;
    }
}