@import "./what-new";

.intro {
  .intro-content {
    h1 {
      // font-weight: 400;
      line-height: 55px;
      margin-bottom: 30px;
      // strong {
      //     font-weight: 500;
      // }
    }

    p {
      font-size: 20px;
      margin-bottom: 30px;
      margin-top: 30px;
    }
  }
}

.intro-btn {
  @include respond('tab-port') {
    margin-bottom: 50px;
  }

  .btn-primary {
    padding: 10px 30px;
    margin-right: 30px;
    font-weight: 600;
    font-size: 16px;
  }

  .btn-outline-primary {
    padding: 10px 30px;
    font-weight: 600;
    font-size: 16px;
  }
}

.main-searchbox-home-page{
  margin-right: 30px;
}

.home-page-secondary-btn{
  margin: 30px;
  margin-top: 60px;
}

// Get Start
.getstart {
  background: #FAFCFE;
}

.getstart-content {
  text-align: center;

  i {
    font-size: 48px;
    color: $primary;
  }

  h3 {
    margin-top: 15px;
    font-size: 20px;
  }
}

// About-us
.about-us {
  background-color: #fff;
  border-bottom: 1px solid $border-color;

  .about-us-content {
    text-align: center;

    .about-us-content-img {
      background: lighten($primary, 45%);
      height: 150px;
      width: 150px;
      border-radius: 5px;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 30px;
      font-size: 96px;
      color: darken($primary, 10%);
      // box-shadow: rgba(218, 225, 233, 0.557) 0px 8px 16px;
      img {
        max-width: 70px;
      }
    }

    h3 {
      margin-bottom: 15px;
    }

    p {
      line-height: 30px;
    }
  }
}

.portfolio_list {
  // max-width: 500px;
  .media {
    margin-bottom: 30px;

    h4 {
      font-size: 22px;
    }

    .port-icon {
      padding: 0.75rem;
      height: 65px;
      width: 65px;
      box-shadow: rgba(218, 225, 233, 0.557) 0px 8px 16px;
      border-radius: 50%;
      // border      : 1px solid $border-color;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 48px;
      margin-right: 20px;
      color: $primary
    }

    p {
      margin-bottom: 0px;

      a {
        color: $primary
      }
    }
  }
}

.portfolio_img {
  img {
    border-radius: 5px;
    box-shadow: 25px 60px 125px -25px rgba(80, 102, 144, .1), 16px 40px 75px -40px rgba(0, 0, 0, .2);
  }
}

.info-content {
  background: $gray-100;
  text-align: center;
  padding: 30px 20px;
  // margin-bottom: 30px;
  height: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  // max-width: 180px;
  i {
    font-size: 36px;
    color: $primary;
  }

  h4 {
    margin-top: 15px;
    font-size: 17px;
    margin-bottom: 0px;
  }
}

// Trade app
.trade-app-content {
  border: 0px;
  // box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, .05);

  .card-body {
    padding: 20px 30px;

    span {
      font-size: 48px;
      margin-bottom: 15px;
      display: inline-block;
      color: $primary;
    }

    h4 {
      font-size: 20px;
      margin-bottom: 15px;
    }

    p {
      min-height: 115px;
      @include respond('tab-land') {
        min-height: 165px;
      }
      @include respond('tab-port') {
        min-height: auto;
      }
    }

    a {
      color: $primary;
      display: flex;
      font-weight: 600;
      align-items: center;

      i {
        margin-left: 15px;
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
}

.get-touch-content {
  // text-align: center;
  padding: 30px;
  margin-bottom: 30px;
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.1);
  background: #fff;
  border-radius: 5px;

  span {
    margin-right: 15px;

    i {
      color: $primary;
      font-size: 42px;
    }
  }

  h4 {
    font-size: 20px;
    // margin-top: 30px;
    margin-bottom: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  p {
    line-height: 27px;
  }
}

.plan {
  margin: 25px 0;
  // box-shadow: 0 1.5rem 4rem rgba(22,28,45,.05);
  // box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
  min-height: 250px;
  border-radius: 20px;

  .plan-header {
    background-color: $primary;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: 1px solid $white;
    // padding: 20px 20px 15px 15px;
    padding: 15px;
    color: $white;
    line-height: 1.5;

    &.premium {
      background: linear-gradient(240.26deg, lighten($gold, 15%) 19.76%, lighten($gold, 5%) 48.17%) !important;
    }

    h4 {
      font-size: 20px;
      margin-bottom: 0px;
      color: $white;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  ul {
    li {
      padding: 5px 0;
      font-size: 17px;
      i {
        font-weight: 700;
        font-size: 18px;
        color: $primary;
        margin-right: 15px;
      }
      img {
        margin-right: 15px;
      }
    }
  }

  p {
    padding: 30px;
  }

  span i {
    color: $primary;
    font-size: 36px;
  }
}

.trust-content {
    margin: 25px 0;
    text-align: center;
    box-shadow: 0 1.5rem 4rem rgba(22,28,45,.05);
    padding: 30px;
    min-height: 250px;
    border-top: 5px solid $primary;
    border-radius: 5px;

    h4 {
      font-size: 20px;
      margin-top: 30px;
      margin-bottom: 15px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    span i {
      color: $primary;
      font-size: 36px;
    }
}

.research-tools {
  h2 {
    margin-bottom: 30px;
    font-weight: 700;
  }

  ul {
    li {
      padding: 10px 0;
      font-size: 17px;
      i {
        font-weight: 700;
        font-size: 18px;
        color: $primary;
        margin-right: 15px;
      }
    }
  }
}